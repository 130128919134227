import React from 'react'
import bg from "../assets/img/Portfoliobg.png"

export const Portfolio = () => {
  return (
    <>
     <img src={bg} alt="" width="30%" style={{opacity:"0.5"}}/>
    </>
   

  )
}
