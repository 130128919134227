import React from "react";
import { Whatwedo } from "./Whatwedo";
import { Link } from "react-router-dom";
// import "./AllClients";
export const Testting = () => {
  return (
    <>
      {/* <div className="not-for-mobile">
        <div className="container">
          <a href="" className='GAQ' style={{textDecoration:"none",color:"white"}}>Explore</a> 
          <Link to="/AllClients" className='GAQ' style={{textDecoration:"none",color:"white"}}>Explore</Link>
        </div>
      </div> */}
    </>
  );
};
